/* eslint-disable no-restricted-globals */
import { Col, Drawer, Menu } from "antd";
import type { MenuProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import SwithChain from "../component/ConnectWallet/SwitchChain";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import WagmiConnect from "../component/ConnectWallet/wagmi-connect";
import queryString from 'query-string';
import { BaseApi } from "../config/api/BaseApi";
import { notiError, notiSuccess } from "../utils/notication";
import './style.scss';

declare const window: Window &
  typeof globalThis & {
      trustwallet: any;
      ethereum: any;
      bitkeep: any;
      okexchain: any;
      safepalProvider: any;
  };

const HeaderApp = () => {

  const { address, connector, isConnected } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let web3:any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
  

  if (connectorId === "io.metamask" || connectorId === "bsc") {
      web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
      web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
      web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
      web3 = new Web3(window.okexchain);
  } else {
      web3 = new Web3(window.ethereum);
  }

  let defaut:any = 'stake';
  let path = window.location.pathname;

  if(path == "/claim"){
    defaut = 'claim';
  }
  if(path == "/leaderboard"){
    defaut = 'leaderboard';
  }
  if(path == "/delegate"){
    defaut = 'delegate';
  }
  if(path == "/profile"){
    defaut = 'profile';
  }
  if(path == "/faq"){
    defaut = 'faq';
  }
  if(path == "/stake"){
    defaut = 'staking-nft';
  }
  if(path == "/"){
    defaut = 'home';
  }

  const [current, setCurrent] = useState(defaut);
  const items: MenuProps['items'] = [
    {
      label: (
        <a href="/">Home</a>
      ),
      key: 'home',
    },
    {
      label: (
        <a href="/stake">Stake</a>
      ),
      key: 'staking-nft',
    },

    {
      label: (
        <>
          {address && (<a href="/profile">Profile</a>)}
        </>
      ),
      key: 'profile',
    },
    {
      label: (
        <a href="/faq">FAQ</a>
      ),
      key: 'faq',
    },
    // {
    //   label: (
    //     <a href="#">Blog</a>
    //   ),
    //   key: 'blog',
    // },
    {
      label: (
        <a href="#">Docs <small className="text-lightblue">(Coming soon)</small></a>
      ),
      key: 'docs',
    }
  ];

  const onClickActiveMenu: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
      setOpen(false);
  };
  
  return (
    <>
      {/* {isMobile ? (
          <>
            <header className="header">
              <div className="main-header">
                <div className="header-left">
                  <div className="icon-bar">
                    <Link to="/"><img src="../../../images/hela-node-logo.svg" alt="" /></Link>
                  </div>
                </div>
                <div className="select-fiter">
                  <WagmiConnect open={open} onClose={onClose} />
                </div>
                <div className="round-connect">
                  <div className="icon-bar" onClick={showDrawer}>
                    <img width="40" src="../../../images/bar-m-1.png" alt="" />
                  </div>
                </div>
              </div>
            </header>
            <Drawer className="drawer-menu" title="" placement="right" onClose={onClose} open={open}>
                <div className="menu-group">
                    <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="vertical" items={items} />
                    {address && (
                      <>
                        <SwithChain />
                      </>
                    )}
                </div>
            </Drawer>
          </>
        ) : (
          <header className="header">
            <div className="main-header">
              <div className="header-left">
                <div className="box-img">
                  <Link to="/"><img src="../../../images/hela-node-logo.svg" alt="" /></Link>
                </div>
              </div>
              <div className="header-center">
                <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="horizontal" items={items} />
                
              </div>
              <div className="header-right">
                {address && (
                  <>
                    <SwithChain />
                  </>
                )}
                
                <div className="round-connect">
                  <WagmiConnect open={open} onClose={onClose} />
                </div>
              </div>
            </div>
          </header>
        )} */}
      
      
    </>
  );
};
export default HeaderApp;
