
import { Col, message, Input, Layout, Row, Progress, Spin, Tag, Empty } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {UnorderedListOutlined, AppstoreOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { Loader } from "react-feather";

import queryString from 'query-string';

const Homepage = () => {

    



    

  
  return (
    <>
        <div className="app-page">
            <HeaderApp />
            <div className="container">
                <div className="logo-game">
                    <img src="../images/game-logo.svg" />
                </div>
                <div className="game-description">
                    Waittttt, GameHiro is Coming
                </div>
                <div className="sub-desc">AVAILABLE 2025</div>
                <div className="mt-15 game-info">
                    <Row gutter={30}>
                        <Col xs={24} sm={24}>
                            <div className="item-info">
                                <div className="">
                                    <img width="30" src="../images/x-w.svg" />
                                </div>
                                <div className="flex-left">
                                    <span className="text-white fsize-18"><a className="text-white" href="https://x.com/GameHiro_X?s=35" target="_blank">Follow us on X</a></span>
                                    <img width="20" src="../images/check.svg" />
                                </div>
                            </div>
                        </Col>
                        {/* <Col xs={24} sm={12}>
                            <div className="item-info">
                                <div className="">
                                    <img width="35" src="../images/gitbook.svg" />
                                </div>
                                <div className="flex-left">
                                    <span className="text-white fsize-18"><a className="text-white" href="" target="_blank">Gitbook Documents Project</a></span>
                                    <img width="20" src="../images/check.svg" />
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </div>
        </div>
    </>
  );
};
export default Homepage;
