import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import Main from "./component/main/Main";

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { arbitrum, bsc, mainnet, polygon } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Homepage from './pages/home';

const queryClient = new QueryClient()
const projectId = '90442772461be13996b4d4766e81fd8f'
// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const helaTestnet: any = {
  id: 666888,
  name: 'HeLa Official Testnet',
  nativeCurrency: { name: 'HLUSD', symbol: 'HLUSD', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.helachain.com'] },
  },
  blockExplorers: {
    default: { name: 'HeLascan', url: 'https://testnet-blockexplorer.helachain.com/' },
  },
 testnet: true
}
const helaMainet: any = {
  id: 8668,
  name: 'HeLa Official Runtime Mainnet',
  nativeCurrency: { name: 'HLUSD', symbol: 'HLUSD', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://mainnet-rpc.helachain.com'] },
  },
  blockExplorers: {
    default: { name: 'HeLascan', url: 'https://blockexplorer.helachain.com/' },
  },
 testnet: false
}

const chains = [bsc, mainnet, arbitrum, helaTestnet, helaMainet] as const

const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: false, // Optional - true by default
  // syncConnectedChain: true, 
  
  
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
  
})

function App() {
    
    return (
        <div className="App">
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <Routes>
                  <Route path={`/`} element={<Homepage />} />
              </Routes>
            </QueryClientProvider>    
          </WagmiProvider>
        </div>
    );
}

export default App;
